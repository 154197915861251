/**
 * @description 判断是否是邮箱
 * @param value
 * @returns {boolean}
 */
export function isEmail(value) {
  const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  return reg.test(value);
}

/** @description 判断是否是密码
 * @param {string} value
 * @returns {boolean}
 */
export function isPassword(value) {
  const reg = /^[a-zA-Z0-9]{6,15}$/;
  return reg.test(value);
}
